import {
  Alert,
  Box,
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/pt-br";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { FaBarcode, FaCopy, FaFilePdf, FaQrcode } from "react-icons/fa";
import { useParams } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import io from "socket.io-client";
import loadIcon from "../../Assets/load.svg";
import { BottomBar } from "../../Components/BottomBar";
import { CreditCardInput } from "../../Components/CreditCardInput";
import { HeaderWithoutInstallments } from "../../Components/HeaderWithoutInstallments";
import { StatusTagPayment } from "../../Components/StatusTagPayment";
import api from "../../services/api";
import { BillData, installment } from "../../types";
import { currency, dateCardNumberMask } from "../../Utils";
import QRCode from "react-qr-code";

import {
  Carddetail,
  CardInputContanier,
  Container,
  ContainerResum,
  ContainerTitle,
  DialogTitle,
  HeaderItemsLabel,
  PullMsg,
  ResumItem,
  TotalValue,
  TotalValueText,
} from "./PaymentOrder.styles";
import axios from "axios";
interface payment {
  status: "NOV" | "PEN" | "CON" | "NEG" | "PPN";
  client_id: number;
  createdAt: string;
  installments: installment[];
  payment_amount: number;
  qrcode: string;
  payment_method: "CREDIT_CARD" | "DEBIT_CARD" | "PIX" | "BILLET";
  updatedAt: string;
  order_id: string;
  _id: string;
  bill?: BillData;
}

const billPdfUrl =
  process.env.REACT_APP_BILL_API_URL ||
  "https://boleto-feirao.s1solucoes.com.br/";

export function PaymentOrder() {
  const { paymentId } = useParams();
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [valid, setValid] = useState("");
  const [validInput] = useState("");
  const [cvv, setCvv] = useState("");
  const [alertCopy, setAlertCopy] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [error, setError] = useState("");
  const [pos, setPos] = useState(false);
  const [load, setLoad] = useState(false);
  const [payment, setPayment] = useState<payment>();
  const [, setIsConnected] = useState(false);
  const [bill, setBill] = useState<BillData | null>(null);
  const [, setLastPong] = useState<string | null>(null);
  // function connectSocket(nit: string) {
  //   let socket = new WebSocket(
  //     // `wss://pagamento-feirao.s1solucoes.com.br/ws/${nit}`
  //     `ws://api-feirao.s1solucoes.com.br`
  //   );
  //   socket.onmessage = function (event) {
  //     // if(JSON.parse(event.data).status === 'CON'){
  //       console.log(event)
  //     if (event.data.split(" ")[0] !== "Socket") {
  //       console.log(event.data);
  //       console.log(JSON.parse(event.data));
  //       let st: stType = JSON.parse(event.data).status;
  //       setPayment({ ...payment, status: st } as payment);
  //     }
  //     // }
  //   };
  //   socket.onclose = function (event) {
  //     if (event.wasClean) {
  //       console.log(
  //         `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
  //       );
  //     } else {
  //       // e.g. server process killed or network down
  //       // event.code is usually 1006 in this case
  //       console.log("[close] Connection died");
  //     }
  //   };

  //   socket.onerror = function (error) {
  //     console.log(`[error]`);
  //     console.log(error);
  //   };
  // }
  const [qrcodeOpen, setQrcodeOpen] = useState(false);
  function connectSocket(nit: string, paym: payment) {
    const socket = io(process.env.REACT_APP_WS_URL as string, {
      transports: ["websocket"],
    });
    setIsConnected(socket.connected);
    socket.on("connect", () => {
      setIsConnected(true);
      console.log("connected: " + nit);
      socket.emit("order", nit);
    });
    socket.on(nit, (event) => {
      setPayment({ ...paym, status: event.data.status } as payment);
      if (event.data.status === "CON") {
        socket.disconnect();
        localStorage.removeItem("@feirão-installments");
      }
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("pong", () => {
      setLastPong(new Date().toISOString());
    });

    // return () => {
    //   socket.off('connect');
    //   socket.off('disconnect');
    //   socket.off('pong');
    // };
  }

  function getPaymensts(paymentId: string) {
    api.get(`api/payments/${paymentId}`).then((resp) => {
      setQrCode(resp.data.qrcode);
      setPayment(resp.data);
      if (resp.data.qrcode && resp.data.status !== "CON") {
        setTimeout(() => connectSocket(resp.data.nit, resp.data), 2000);
      }
    });
  }

  useEffect(() => {
    // getClientLogin();
    if (paymentId) {
      getPaymensts(paymentId);
    }
  }, [paymentId]);

  function pay() {
    setLoad(true);
    api
      .post("/api/payments/create-card", {
        orderId: paymentId,
        // amount: payment?.installments?.reduce((x, y) => x + y.total, 0),
        number: number.replace(/\D/g, ""),
        expiryDate: moment(valid, "YYYY-MM").format("MMYY"),
        securityCode: cvv,
      })
      .then(() => {
        api.get(`api/payments/${paymentId}`).then((resp) => {
          console.log(resp.data);
          setPayment(resp.data);
          connectSocket(resp.data.nit, resp.data);
        });
      })
      .finally(() => {
        setLoad(false);
      });
  }
  function payPix() {
    setLoad(true);
    setError("");
    api
      .post(`/api/payments/create-pix/${paymentId}`, {})
      .then((response) => {
        setQrCode(response.data.qr_code);
        api.get(`api/payments/${paymentId}`).then((resp) => {
          console.log(resp.data);
          setPayment(resp.data);
          connectSocket(resp.data.nit, resp.data);
          // connectSocket();
          // connectSocket('12321312312kdasmd')
        });
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => {
        setLoad(false);
      });
  }
  function payBolleto() {
    setLoad(true);
    setError("");
    api
      .post(`/api/payments/create-boleto/${payment?.order_id}`, {})
      .then((response) => {
        setBill(response.data);
        api.get(`api/payments/${paymentId}`).then((resp) => {
          setPayment(resp.data);
        });
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function unsecuredCopyToClipboard(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  }
  async function copyText(t: string, mode: string) {
    if (window.isSecureContext && navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(t);
        setAlertCopy(`Código ${mode} copiado!`);
        setTimeout(() => {
          setAlertCopy("");
        }, 4000);
      } catch (e) {
        console.log(e);
      }
    } else {
      unsecuredCopyToClipboard(t);
      setAlertCopy("Código pix copiado!");
      setTimeout(() => {
        setAlertCopy("");
      }, 4000);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BottomBar />
      <HeaderWithoutInstallments />
      <PullToRefresh
        onRefresh={() =>
          new Promise(function (resolve, reject) {
            getPaymensts(paymentId as string);
            resolve("Stuff worked!");
          })
        }
        pullDownThreshold={85}
        resistance={2.5}
        pullingContent={<PullMsg>Puxe para atualizar!</PullMsg>}
      >
        <Container>
          <Carddetail>
            <ContainerTitle>
              <DialogTitle>
                Resumo:{" "}
                <StatusTagPayment
                  status={
                    payment?.status as "NOV" | "PEN" | "CON" | "NEG" | string
                  }
                />
              </DialogTitle>
            </ContainerTitle>
            <ContainerResum>
              {payment?.installments.map((x) => (
                <ResumItem key={1}>
                  <HeaderItemsLabel>
                    Parcela {x.installment_number} - contrato {x.contract_id}
                  </HeaderItemsLabel>
                  <HeaderItemsLabel>{currency(x.total)}</HeaderItemsLabel>
                </ResumItem>
              ))}
            </ContainerResum>
            <TotalValue>
              <TotalValueText>Total:</TotalValueText>
              <TotalValueText>
                {currency(
                  payment
                    ? payment?.installments?.reduce((x, y) => x + y.total, 0)
                    : 0
                )}
              </TotalValueText>
            </TotalValue>

            {payment &&
              (payment.payment_method === "CREDIT_CARD" ||
                payment.payment_method === "DEBIT_CARD") &&
              payment.status !== "CON" &&
              payment.status !== "NEG" && (
                <>
                  <DialogTitle>Dados do cartão:</DialogTitle>

                  <CardInputContanier>
                    <CreditCardInput
                      values={{
                        name,
                        valid: valid
                          ? moment(valid, "YYYY-MM").format("MM/YY")
                          : "",
                        cvv,
                        number,
                      }}
                      pos={pos}
                    />
                    <Box sx={{ flex: 1 }}>
                      <TextField
                        size="small"
                        sx={{ width: "100%", mb: 2 }}
                        required
                        id="name"
                        label="Nome no cartão"
                        onChange={(event) => setName(event.target.value)}
                      />

                      <TextField
                        sx={{ width: "100%", mb: 2 }}
                        size="small"
                        required
                        id="number-card"
                        label="Numero do cartão"
                        value={number}
                        onChange={(event) =>
                          setNumber(dateCardNumberMask(event.target.value))
                        }
                      />
                      <div style={{ display: "flex" }}>
                        {/* <TextField
                          sx={{ width: "100%", mr: 2 }}
                          size="small"
                          required
                          id="valid-card"
                          label="Validade"
                          value={valid}
                          type="month"
                          placeholder="."
                          onChange={(event) => setValid(event.target.value)}
                        /> */}
                        <DatePicker
                          views={["year", "month"]}
                          label="Validade"
                          InputProps={{
                            size: "small",
                            value: validInput,
                            sx: { mr: 2 },
                          }}
                          minDate={moment()}
                          mask="__/__"
                          disableMaskedInput
                          value={valid}
                          inputFormat={"MM/YY"}
                          onChange={(newValue, v) => {
                            if (newValue) {
                              setValid(
                                newValue.isValid()
                                  ? moment(newValue).format("YYYY-MM")
                                  : ""
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} helperText={null} />
                          )}
                        />
                        <TextField
                          onFocus={() => setPos(true)}
                          onBlur={() => setPos(false)}
                          onChange={(event) => setCvv(event.target.value)}
                          size="small"
                          inputProps={{ maxLength: 4 }}
                          sx={{ width: "100%" }}
                          required
                          id="cvv-card"
                          label="CVV"
                        />
                      </div>
                    </Box>
                  </CardInputContanier>
                  <Button
                    sx={{ mt: "1rem" }}
                    variant="contained"
                    color="success"
                    disabled={
                      load ||
                      cvv.length < 3 ||
                      !valid ||
                      !name ||
                      number.length < 19
                    }
                    onClick={pay}
                  >
                    Pagar
                  </Button>
                </>
              )}

            {payment &&
              payment.payment_method === "PIX" &&
              payment.status !== "CON" &&
              payment.status !== "NEG" && (
                <Box
                  sx={{ display: "flex", flexDirection: "column", mt: "1rem" }}
                >
                  <List>
                    <ListItem>
                      <ListItemText>
                        1 - Abra o app do seu banco e entre no ambiente Pix.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        2 - Escolha a opção pagar com QR Code e escaneie o
                        código abaixo.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        3 - Confirme as informações e finalize seu pagamento.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        4 - Você pode copiar o código pix clicando no botão
                        CÓPIAR CÓDIGO PIX.
                      </ListItemText>
                    </ListItem>
                  </List>
                  {qrCode || payment.qrcode ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          mb: "1rem",
                        }}
                      >
                        <QRCodeSVG width={300} height={250} value={qrCode} />
                      </Box>
                      <Button
                        variant="contained"
                        sx={{ mx: "auto" }}
                        color="primary"
                        onClick={() => copyText(qrCode, "PIX")}
                      >
                        <FaCopy style={{ marginRight: "0.7rem" }} /> Copiar
                        código do pix
                      </Button>
                      {alertCopy && (
                        <span style={{ margin: "1rem auto" }}>{alertCopy}</span>
                      )}
                    </>
                  ) : (
                    <>
                      {load && (
                        <>
                          <img
                            src={loadIcon}
                            style={{ margin: "1rem auto" }}
                            width="130px"
                            alt=""
                          />
                          {/* <Alert severity="info">
                            Seu QR code pode demorar até 3 minutos para ficar
                            pronto
                          </Alert> */}
                        </>
                      )}
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Button
                          variant="contained"
                          sx={{ mx: "auto", mt: "1rem" }}
                          color="primary"
                          onClick={payPix}
                          disabled={load}
                        >
                          {/* <FaQrcode style={{ marginRight: "0.7rem" }} />{" "} */}
                          {load
                            ? "Gerando QRcode (pode levar alguns minutos)"
                            : "gerar qrcode"}
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              )}
            {payment && payment.payment_method === "BILLET" && (
              <Box
                sx={{ display: "flex", flexDirection: "column", mt: "1rem" }}
              >
                <List>
                  <ListItem>
                    <ListItemText>
                      1 - Abra o app do seu banco e selecione pagar boleto.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      2 - Escolha a opção pagar com codigo de barras e cole o
                      código gerado abaixo.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      3 - Confirme as informações e finalize seu pagamento.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      4 - Você pode copiar o código do boleto clicando no botão
                      CÓPIAR CÓDIGO DO BOLETO.
                    </ListItemText>
                  </ListItem>
                </List>
                {bill?.linhaDig10 || payment?.bill?.linhaDig10 ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                        mb: "1rem",
                      }}
                    >
                      <h5>
                        <FaBarcode /> Seu boleto foi gerado!
                      </h5>
                      <Box
                        style={{
                          border: "1px solid #bababa",
                          padding: "1rem",
                          borderRadius: "8px",
                          backgroundColor: "#f5f5f5",
                        }}
                      >
                        {bill?.linhaDig10 || payment?.bill?.linhaDig10}
                      </Box>
                      {/* <QRCodeSVG width={300} height={250} value={qrCode} /> */}
                    </Box>
                    <Button
                      variant="contained"
                      sx={{ mx: "auto" }}
                      color="primary"
                      onClick={() =>
                        copyText(
                          bill?.linhaDig10 || payment?.bill?.linhaDig10 || "",
                          "boleto"
                        )
                      }
                    >
                      <FaCopy style={{ marginRight: "0.7rem" }} /> Copiar código
                      do Boleto
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ mx: "auto", mt: "1rem" }}
                      color="primary"
                      onClick={() =>
                        axios
                          .post(
                            billPdfUrl,
                            {
                              expirationDay: moment(
                                payment.bill?.dataVenctoBol10,
                                "DD/MM/YYYY"
                              ).format("YYYY-MM-DD"),
                              processingDate: moment(payment.createdAt).format(
                                "YYYY-MM-DD"
                              ),
                              documentDate: moment(payment.createdAt).format(
                                "YYYY-MM-DD"
                              ),
                              value: payment.bill?.valMoeda10,
                              documentNumber: payment.bill?.ctrlPartic10,
                              ourNumber: payment.bill?.ctrlPartic10,
                              payer: {
                                name: payment.bill?.nomeSacado10,
                                registerNumber: "",
                                street: payment.bill?.endSacado10,
                                number: "",
                                complement: " ",
                                district: payment.bill?.baiSacado10,
                                city: payment.bill?.cidSacado10,
                                state: payment.bill?.ufSacado10,
                                postalCode: "0",
                              },
                            },
                            {
                              responseType: "blob", // Essencial para tratar a resposta como binário
                            }
                          )
                          .then((response) => {
                            const url = window.URL.createObjectURL(
                              new Blob([response.data])
                            );
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", "boleto-feirao.pdf");
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          })
                          .catch((error) => {
                            console.error("Erro ao baixar o arquivo:", error);
                          })
                      }
                    >
                      <FaFilePdf style={{ marginRight: "0.7rem" }} /> Baixar
                      boleto
                    </Button>
                    {/* <Button
                      variant="contained"
                      sx={{ mx: "auto", mt: "1rem" }}
                      color="primary"
                      onClick={() => {
                        copyText(
                          bill?.wqrcdPdraoMercd ||
                            payment?.bill?.wqrcdPdraoMercd ||
                            "",

                          "Pix"
                        );
                      }}
                    >
                      <FaQrcode style={{ marginRight: "0.7rem" }} />
                      Copiar código Pix
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ mx: "auto", mt: "1rem" }}
                      color="primary"
                      onClick={() => {
                        setQrcodeOpen(true);
                      }}
                    >
                      <FaQrcode style={{ marginRight: "0.7rem" }} />
                      Gerar qrcode pix
                    </Button> */}
                    <Modal open={qrcodeOpen} onClose={()=> setQrcodeOpen(false)}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                          width: "100vw",
                        }}
                      >
                        <Card
                          style={{
                            padding: "1rem",
                            borderRadius: "8px",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <Button
                            style={{
                              alignSelf: "flex-end",
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setQrcodeOpen(false);
                            }}
                          >
                            Voltar
                          </Button>{" "}
                          <strong>Use o qrcode para pagar via pix! </strong>
                          <QRCode
                            value={
                              bill?.wqrcdPdraoMercd ||
                              payment?.bill?.wqrcdPdraoMercd ||
                              ""
                            }
                          />
                        </Card>
                      </div>
                    </Modal>
                    {alertCopy && (
                      <span style={{ margin: "1rem auto" }}>{alertCopy}</span>
                    )}
                  </>
                ) : (
                  <>
                    {load && (
                      <>
                        <img
                          src={loadIcon}
                          style={{ margin: "1rem auto" }}
                          width="130px"
                          alt=""
                        />
                        {/* <Alert severity="info">
                        Seu QR code pode demorar até 3 minutos para ficar
                        pronto
                      </Alert> */}
                      </>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        variant="contained"
                        sx={{ mx: "auto", mt: "1rem" }}
                        color="primary"
                        onClick={payBolleto}
                        disabled={load}
                      >
                        {/* <FaQrcode style={{ marginRight: "0.7rem" }} />{" "} */}
                        {load
                          ? "Gerando QRcode (pode levar alguns minutos)"
                          : "Gerar boleto"}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            )}
            {error && (
              <Alert severity="error" sx={{ mt: "1rem" }}>
                {error}
              </Alert>
            )}
          </Carddetail>
        </Container>
      </PullToRefresh>
    </LocalizationProvider>
  );
}
